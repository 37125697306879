import axiosConfig from '../axiosConfig'
import SBTracking from './tracking';

//login user
export function login(username, password) {
    const requestOptions = {        
        email:username,
        password:password 
    };
    return axiosConfig.post(`/login`, requestOptions)
    .then(res => {
        
        if (  res.status === 200 ){

            localStorage.setItem('token', JSON.stringify(res.data.success.token) );
            // localStorage.setItem('user_id', JSON.stringify(res.data.success.user_id) );

            axiosConfig.defaults.headers.common['Authorization'] = `Bearer ${res.data.success.token}`;
            axiosConfig.defaults.headers.post['Content-Type'] = 'application/json';

            const responseCheckphotoUserType =  checkPhotoUserTypeAccess() ;
            
            SBTracking.gtmTrack({
                'event':'app_login',
                'page_category':'Welcome', 
                'page_name':'App - Welcome'
             })

            responseCheckphotoUserType.then( res => {
                if( res.status === 200){
                    localStorage.setItem('photographers_access', JSON.stringify(res.data.photographers_access) );
                    localStorage.setItem('directors_access', JSON.stringify(res.data.directors_access) );
                    window.location.href = "/"
                }
            }).catch(error => {
                window.location.href = "/"
            })



        }

    }).catch(error => {
        console.error(error)
    });
}

//logout out user
export function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('token');
}

 // utils
export function isLoggedin(){
    return localStorage.getItem('token') ? true : false;
}

//Send Password Email
export function sendPasswordEmail(email) {
    return  axiosConfig.post(`password/email`, {
        email: email,
    })
}


//Reset Password
export function resetPassword(email,password,password_confirmation,token) {
    return  axiosConfig.post(`password/reset`, {
        token: token,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
    })
}

function  checkPhotoUserTypeAccess() {
    return  axiosConfig.get(`user/check-photo-user-type-access`)
}