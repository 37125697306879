import React, {useRef, useState, useEffect} from 'react';
import { userService } from '../../services/userServices';
import { getFileName } from '../../helpers/FileCaching';
import {TweenMax, Power3} from 'gsap';
import { useParams, useHistory } from 'react-router-dom';
import classes from './PasswordResetPage.module.scss';


const globalImages = require.context('../../images', true, /.*\.(gif|png|jpe?g|svg)$/i);

const PasswordResetPage = () => {
    let history = useHistory()
    const authed = userService.isLoggedin();
    if( authed) history.push("/");

    let { token } = useParams();

    const iPadMediaQ = window.matchMedia("(min-width: 1200px)");
    const logoRef = useRef();
    const formRef = useRef();
    const tempRef = useRef();

    const [userEmail, setUserEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    // const [token, setToken] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const [error, setError] = useState(false);

    const initialCount = () => Number(window.localStorage.getItem('count') || 0)
    const [count, setCount] = useState(initialCount)
    const increment = () => setCount(count + 1)

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        increment()


            userService.resetPassword(userEmail,password,passwordConfirmation,token).then( res => {        
                history.push("/login");
            }).catch( err => {
                if ( err.response.status === 422 ){

                    var msg = "The credentials do not match our records.";
                    if(err.response.data.errors){
                        msg = err.response.data.errors.password;
                    } else if(err.response.data.error) {
                        msg = "The credentials do not match our records.";
                    } else {
                        msg = err.response.data.message;
                    }

                    setError(msg);
                }
            })
    }

    useEffect( () => {
        window.localStorage.setItem('count', count)
    }, [count])

    useEffect(() => {
        if(iPadMediaQ.matches){
            TweenMax.set(logoRef.current, {scale:3.9, y:100});
            TweenMax.set([formRef.current, tempRef.current], {opacity:0});

            TweenMax.to(logoRef.current, .5, {delay: .5, scale:1, y:0,  ease: Power3.easeOut});
            TweenMax.to([formRef.current, tempRef.current], .5, {delay: .9, opacity:1,  ease: Power3.easeOut});
        }
    },[iPadMediaQ.matches]);

    return(
        <div className={classes.PasswordReset}>
            <div className={`col-md-6 col-md-offset-3`}>
                <img ref={logoRef} className={classes.PasswordReset__logo} src={ getFileName(globalImages, `logo.svg`)} alt="Squint Box Logo" />
                <form ref={formRef}  className={classes.PasswordReset__form} name="form" onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <input type="email" 
                                className={`col-8 ${classes.PasswordReset__form_input} `} 
                                name="userEmail" 
                                placeholder="Email" 
                                value={userEmail} 
                                onChange={e => setUserEmail(e.target.value)} />
                    </div>

                    <div className='form-group'>
                        <input type="password" 
                            className={`col-8 ${classes.PasswordReset__form_input} ` + (submitted && !password ? `${classes.PasswordReset__form_input__invalid}` : '') } 
                            name="password" 
                            placeholder="Password" 
                            value={password} 
                            onChange={e => setPassword(e.target.value)} 
                        />
                        {submitted && !password &&
                            <div className="help-block text-danger">Password is required</div>
                        }
                    </div>

                    <div className='form-group'>
                        <input type="password" 
                            className={`col-8 ${classes.PasswordReset__form_input} ` + (submitted && !passwordConfirmation ? `${classes.PasswordReset__form_input__invalid}` : '') } 
                            name="passwordConfirmation" 
                            placeholder="Confirm Password" 
                            value={passwordConfirmation} 
                            onChange={e => setPasswordConfirmation(e.target.value)} 
                        />
                        {submitted && !passwordConfirmation &&
                            <div className="help-block text-danger">Confirm Password is required</div>
                        }
                    </div>

                    <div className="form-group">
                        <button type="submit" className={classes.PasswordReset__submit_button}>Update Password</button>
                    </div>

                    {error &&                        
                        <span className="text-danger">{error}</span>
                    }
                </form>
            </div>
        </div>
    )
}

export default PasswordResetPage;