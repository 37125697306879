import React, {useRef, useState, useEffect} from 'react';
import {userService} from '../../services/userServices';
import {getFileName} from '../../helpers/FileCaching';
import {TweenMax, Power3} from 'gsap';

import classes from './PasswordEmailPage.module.scss';


const globalImages = require.context('../../images', true, /.*\.(gif|png|jpe?g|svg)$/i);

const PasswordEmailPage = (props) => {

    const iPadMediaQ = window.matchMedia("(min-width: 1200px)");
    const logoRef = useRef();
    const formRef = useRef();
    const tempRef = useRef();

    const [userEmail, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);


    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const initialCount = () => Number(window.localStorage.getItem('count') || 0)
    const [count, setCount] = useState(initialCount)
    const increment = () => setCount(count + 1)

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true);
        increment()

        userService.sendPasswordEmail(userEmail).then( res => {        

            if ( res.status === 200 ){
                setSuccess("You recive an email to reset your password.")
            }
        }).catch( err => {
            if ( err.response.status === 422 ){
                setError("Sorry something went wrong please contact you admin.")
            }
        })
    }

    useEffect( () => {
        window.localStorage.setItem('count', count)
    }, [count])

    useEffect(() => {
        if(iPadMediaQ.matches){
            TweenMax.set(logoRef.current, {scale:3.9, y:100});
            TweenMax.set([formRef.current, tempRef.current], {opacity:0});

            TweenMax.to(logoRef.current, .5, {delay: .5, scale:1, y:0,  ease: Power3.easeOut});
            TweenMax.to([formRef.current, tempRef.current], .5, {delay: .9, opacity:1,  ease: Power3.easeOut});
        }
    },[iPadMediaQ.matches]);

    return(
        <div className={classes.PasswordEmail}>
            <div className={`col-md-6 col-md-offset-3`}>
                <img ref={logoRef} className={classes.PasswordEmail__logo} src={ getFileName(globalImages, `logo.svg`)} alt="Squint Box Logo" />
                <h1>Forgotten your password?</h1>
                <span>Please enter your email address below and we will email you a link to reset your password</span>
                <form ref={formRef}  className={classes.PasswordEmail__form} name="form" onSubmit={handleSubmit}>
                    <div className='form-group'>
                        <input type="text" 
                            className={`col-8 ${classes.PasswordEmail__form_input} ` + (submitted && !userEmail ? `${classes.PasswordEmail__form_input__invalid}` : '') } 
                            name="userEmail" 
                            placeholder="Email address" 
                            value={userEmail} onChange={e => setEmail(e.target.value)} 
                        />
                        {submitted && !userEmail &&
                            <div className="help-block text-danger">Email is required</div>
                        }
                    </div>

                    <div className="form-group">
                        <button type="submit" className={classes.PasswordEmail__submit_button}>Send Email</button>
                    </div>

                    {error &&                        
                        <span className="text-danger">{error}</span>
                    }

                    {success &&                        
                        <span className="text-success">{success}</span>
                    }
                </form>
            </div>
        </div>
    )
}

export default PasswordEmailPage;