import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './Logout.module.scss';
import { userService } from '../../services/userServices'
import SBTracking from '../../services/tracking'

const Logout = () => {

    let uselocation = useLocation()
    function handleClick(e) {     
        SBTracking.gtmTrack({
            'event':'logout',
            'page_category': SBTracking.SessionData.pageCategory, 
            'page_name': SBTracking.SessionData.pageName,
        })
        userService.logout()        
    }

    
    return(
        <div className={classes.Logout}>    
            <Link to={{
                state:  {userType: ( uselocation.state) ? uselocation.userType : null }
            }} className={classes.Logout__link} onClick={handleClick} >Logout</Link>
        </div>
    )
}

export default Logout;


